export const redirect = [

  {from:'^/linkedin-registration', to: '/blog/linkedin-registration'},
  {from:'^/registraciya-mail-ru', to: '/blog/registraciya-mail-ru'},
  {from:'^/sms-aktivacia-virtualnyj-nomer-telefona-v-pomos', to: '/blog/sms-aktivacia-virtualnyj-nomer-telefona-v-pomos'},
  {from:'^/kak-kupit-vremennye-nomera-telefonov-na-den', to: '/blog/kak-kupit-vremennye-nomera-telefonov-na-den'},
  {from:'^/kak-zavesti-virtualnyj-nomer-dlya-registracii', to: '/blog/kak-zavesti-virtualnyj-nomer-dlya-registracii'},
  {from:'^/dla-cego-nuzen-virtualnyj-nomer-i-kak-ego-polucit', to: '/blog/dla-cego-nuzen-virtualnyj-nomer-i-kak-ego-polucit'},
  {from:'^/gde-kupit-virtualnyj-nomer-telefona', to: '/blog/gde-kupit-virtualnyj-nomer-telefona'},
  {from:'^/kak-polzovatsa-virtualnym-nomerom-telefona-effektivno', to: '/blog/kak-polzovatsa-virtualnym-nomerom-telefona-effektivno'},
  {from:'^/kak-rabotaet-virtualnyj-nomer-telefona', to: '/blog/kak-rabotaet-virtualnyj-nomer-telefona'},
  {from:'^/chto-takoe-virtualnyj-nomer-telefona', to: '/blog/chto-takoe-virtualnyj-nomer-telefona'},
  {from:'^/podklyuchenie-virtualnyh-nomerov-na-servise', to: '/blog/podklyuchenie-virtualnyh-nomerov-na-servise'},
  {from:'^/virtualnyy-nomer-plyusy-i-minusy', to: '/blog/virtualnyy-nomer-plyusy-i-minusy'},
  {from:'^/registraciya-whatsapp-bez-nomera-telefona-za-paru-secund', to: '/blog/registraciya-whatsapp-bez-nomera-telefona-za-paru-secund'},
  {from:'^/registracia-v-telegramm-bez-nomera-telefona-v-2021-godu', to: '/blog/registracia-v-telegramm-bez-nomera-telefona-v-2021-godu'},
  {from:'^/kak-zaregistrirovatsya-na-mambe-bez-nomera', to: '/blog/kak-zaregistrirovatsya-na-mambe-bez-nomera'},
  {from:'^/registraciya-v-odnoklassnikah-bez-nomera', to: '/blog/registraciya-v-odnoklassnikah-bez-nomera'},
  {from:'^/zaregistrirovatsya-na-amazon', to: '/blog/zaregistrirovatsya-na-amazon'},
  {from:'^/registraciya-v-didi-bez-nomera-telefona', to: '/blog/registraciya-v-didi-bez-nomera-telefona'},
  {from:'^/can-you-use-discord-without-a-sim-card', to: '/blog/can-you-use-discord-without-a-sim-card'},
  {from:'^/kak-zaregistrirovatsya-v-feysbuke-bez-nomera-telefona', to: '/blog/kak-zaregistrirovatsya-v-feysbuke-bez-nomera-telefona'},
  {from:'^/how-to-set-up-gmail', to: '/blog/how-to-set-up-gmail'},
  {from:'^/registraciya-v-imo-bez-nomera-telefona', to: '/blog/registraciya-v-imo-bez-nomera-telefona'},
  {from:'^/kak-sozdat-akkaunt-v-instagram-bez-nomera', to: '/blog/kak-sozdat-akkaunt-v-instagram-bez-nomera'},
  {from:'^/registraciya-v-kakaotalk-bez-nomera-telefona', to: '/blog/registraciya-v-kakaotalk-bez-nomera-telefona'},
  {from:'^/kak-zaregistrirovatsya-v-messendzhere-lajn', to: '/blog/kak-zaregistrirovatsya-v-messendzhere-lajn'},
  {from:'^/registraciya-v-microsoft-bez-nomera-telefona', to: '/blog/registraciya-v-microsoft-bez-nomera-telefona'},
  {from:'^/registraciya-v-naver-bez-nomera-telefona', to: '/blog/registraciya-v-naver-bez-nomera-telefona'},
  {from:'^/kak-zaregistrirovatsya-na-netfliks-bez-nomera', to: '/blog/kak-zaregistrirovatsya-na-netfliks-bez-nomera'},
  {from:'^/is-it-possible-to-register-paypal-without-number', to: '/blog/is-it-possible-to-register-paypal-without-number'},
  {from:'^/kak-zaregistrirovat-tik-tok-bez-nomera-telefona', to: '/blog/kak-zaregistrirovat-tik-tok-bez-nomera-telefona'},
  {from:'^/kak-zaregistrirovat-profil-v-tinder-bez-nomera', to: '/blog/kak-zaregistrirovat-profil-v-tinder-bez-nomera'},
  {from:'^/registraciya-v-twitter-bez-nomera-telefona', to: '/blog/registraciya-v-twitter-bez-nomera-telefona'},
  {from:'^/registraciya-v-uber-bez-nomera-telefona', to: '/blog/registraciya-v-uber-bez-nomera-telefona'},
  {from:'^/how-to-activate-viber', to: '/blog/how-to-activate-viber'},
  {from:'^/kak-sozdat-stranicu-v-vk-bez-nomera-telefona', to: '/blog/kak-sozdat-stranicu-v-vk-bez-nomera-telefona'},
  {from:'^/registraciya-v-wechat-bez-nomera-telefona', to: '/blog/registraciya-v-wechat-bez-nomera-telefona'},
  {from:'^/registraciya-v-yahoo-bez-nomera-telefona', to: '/blog/registraciya-v-yahoo-bez-nomera-telefona'},
  {from:'^/kak-zaregistrirovatsya-na-avito-bez-nomera', to: '/blog/kak-zaregistrirovatsya-na-avito-bez-nomera'},
  {from:'^/how-to-create-an-account-on-blablacar-no-phone-number', to: '/blog/how-to-create-an-account-on-blablacar-no-phone-number'},
  {from:'^/registraciya-v-drom-bez-nomera-telefona', to: '/blog/registraciya-v-drom-bez-nomera-telefona'},
  {from:'^/kak-vojti-v-metro-bez-nomera-i-sovershat-pokupki', to: '/blog/kak-vojti-v-metro-bez-nomera-i-sovershat-pokupki'},
  {from:'^/registraciya-v-grindr-bez-nomera-telefona', to: '/blog/registraciya-v-grindr-bez-nomera-telefona'},
  {from:'^/registraciya-v-kfc-bez-nomera-telefona', to: '/blog/registraciya-v-kfc-bez-nomera-telefona'},
  {from:'^/registraciya-v-magnit-bez-telefonnogo-nomera', to: '/blog/registraciya-v-magnit-bez-telefonnogo-nomera'},
  {from:'^/registraciya-v-olx-bez-nomera-telefona', to: '/blog/registraciya-v-olx-bez-nomera-telefona'},
  {from:'^/registraciya-v-protonmail-bez-nomera-telefona', to: '/blog/registraciya-v-protonmail-bez-nomera-telefona'},
  {from:'^/kak-proiskhodit-registraciya-v-sportmaster-bez', to: '/blog/kak-proiskhodit-registraciya-v-sportmaster-bez'},
  {from:'^/registraciya-v-sbermarket-bez-nomera-telefona', to: '/blog/registraciya-v-sbermarket-bez-nomera-telefona'},
  {from:'^/registraciya-v-tvich-bez-nomera-telefona', to: '/blog/registraciya-v-tvich-bez-nomera-telefona'},
  {from:'^/registraciya-v-vernom-bez-nomera-telefona', to: '/blog/registraciya-v-vernom-bez-nomera-telefona'},
  {from:'^/registraciya-vo-vkusville-bez-nomera-telefona', to: '/blog/registraciya-vo-vkusville-bez-nomera-telefona'},
  {from:'^/registraciya-v-wildberries-bez-nomera-telefona', to: '/blog/registraciya-v-wildberries-bez-nomera-telefona'},
  {from:'^/how-to-activate-viber-without-phone-number', to: '/blog/how-to-activate-viber-without-phone-number'},
  {from:'^/how-to-create-multiple-accounts-on-twitter', to: '/blog/how-to-create-multiple-accounts-on-twitter'},
  {from:'^/how-to-create-two-yandex-accounts-step-by-step-instructions', to: '/blog/how-to-create-two-yandex-accounts-step-by-step-instructions'},
  {from:'^/how-to-make-a-second-discord-account', to: '/blog/how-to-make-a-second-discord-account'},
  {from:'^/basic-methods-of-creating-an-account-on-aliexpress-without-phone-number', to: '/blog/basic-methods-of-creating-an-account-on-aliexpress-without-phone-number'},
  {from:'^/kak-sdelat-vtoroj-akkaunt-v-vatsape-v-2022-godu', to: '/blog/kak-sdelat-vtoroj-akkaunt-v-vatsape-v-2022-godu'},
  {from:'^/kak-sdelat-vtoroj-akkaunt-v-telegramme', to: '/blog/kak-sdelat-vtoroj-akkaunt-v-telegramme'},

  {from: '^/en/(.*)$', to: '/$1'},
  {from: '^/en/', to: '/'},
  {from: '^/en', to: '/'},


  {
    // eslint-disable-next-line
    from: '(?!^\/$|^\/[?].*$)(.*\/[?](.*)$|.*\/$)',
    to: (from : any, req : any) => {
      const base = req._parsedUrl.pathname.replace(/\/$/, '');
      const search = req._parsedUrl.search;
      return base + (search != null ? search : '');
    }
  }
];




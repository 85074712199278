//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {themeChange} from "theme-change";
import CurrencyToggle from "~/components/common/header/CurrencyToggle";
import LangToggle from "~/components/common/header/LangToggle";
import NavigationPanel from '~/components/profile/NavigationPanel.vue';
import HeaderMobile from "~/components/layouts/HeaderMobile.vue";

export default {
  data() {
    return {
      isFixed: false,
      scrlElem: null,
      isShowSearch: false
    };
  },

  components: {
    LangToggle,
    HeaderMobile,
    NavigationPanel,
    Search: () => import('~/components/common/Search.vue'),
    CurrencyToggle
  },

  props: {
    menu: {
      type: Array,
    },
    isLoggedIn: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  // watch: {
  //   $route: function () {
  //     setTimeout(() => {
  //       this.scrollToFeedback();
  //     }, 2000);
  //   },
  // },

  computed: {
    // isUserAuth() {
    //   return this.$store.getters['userAuthLocal/isUserAuth'];
    // },

    hreflang() {
      return this?.$i18n?.locale || this?.$i18n?.defaultLocale || "en";
    },
    getLogoPath() {
      return `/${this.hreflang === 'en' ? '' : this.hreflang}`;
    }
  },

  mounted() {
    themeChange(false);

    // setTimeout(() => {
    //   this.scrollToFeedback();
    // }, 2000);


  },

  methods: {
    changeOpenSearch: function () {
      this.isShowSearch = !this.isShowSearch;
    },

    // openFeedback: async function () {
    //   const currentRoutePath = window.location?.pathname || "/";
    //   if (
    //     currentRoutePath === "/en/" ||
    //     currentRoutePath === "/en" ||
    //     currentRoutePath === "/" ||
    //     currentRoutePath === "" ||
    //     currentRoutePath === "/ru/" ||
    //     currentRoutePath === "/ru" ||
    //     currentRoutePath === "ru" ||
    //     currentRoutePath === "/cn/" ||
    //     currentRoutePath === "/cn" ||
    //     currentRoutePath === "cn"
    //   ) {
    //     this.scrollToFeedback(true);
    //
    //     return null;
    //   }
    //
    //   await this.$cookies.set("scroll-to-feedback", "true");
    //   this.$router.push(this.localePath("/"));
    // },
    // scrollToFeedback: function (isHomePage = false) {
    //   const isAvailableScroll = this.$cookies.get("scroll-to-feedback");
    //   if (!isAvailableScroll && !isHomePage) {
    //     return null;
    //   }
    //
    //   const formFeedback = document.getElementById("feedback");
    //   if (!formFeedback) {
    //     return null;
    //   }
    //
    //   formFeedback.scrollIntoView({block: "start", behavior: "smooth"});
    //
    //   this.$cookies.remove("scroll-to-feedback");
    // },
  },
};

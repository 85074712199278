//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ProductCardLoad",
};

export default {

  header: {
    cart: "header.cart",
    profile:"header.profile",
    loginButton:"header.loginButton",
    languageToggle:{
      en:"header.languageToggle.en",
      ru:"header.languageToggle.ru",
    }
  },
  homepage:{
    leftCol:{
      inputSearch:{
        placeholder:"homepage.leftCol.inputSearch.placeholder"
     }
    }
  },
  profileMenu: {
    accountSetting: "Настройки акаунта",
    orders:"Заказы",
    wishList:"Избранное",
    notifications:"Уведомления",
    legalNotice:"Правовая информация",
    helpCenter:"Центр Помощи",
    changePassword:"Изменить пароль",
    logout:"Выйти"
  },

  accountSetting: {
    personalInformation: {
      title: "Персональная информация",
      firstName: {
        title:"Имя *",
        placeholder:"Введите имя"
      },
      lastName: {
        title:"Фамилия *",
        placeholder:"Введите фамилию"
      },
      phone:{
        title:"Телефон *",
        placeholder:"Введите номер"
      },
    },
    accountInformation:{
      title:"Учетная запись",
      email:{
        title:"Email *",
        placeholder:"Введите email"
      },
      password:{
        title:"Пароль *",
        placeholder:"Введите пароль",
      },
      confirmPassword:{
        title:"Подтвердить *",
        placeholder:"Подтвердите пароль",
      },
    },
    saveChange:"Сохранить"
  },
  footer:{
    subscribe:{
      title:"Подписаться сейчас",
      description:"Введите ваш email для подписски на рассылку",
      placeholder:"Введите email",
    }
  }
}

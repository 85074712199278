//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

let timeOutSearch = null;
export default {
  name: 'CustomAutocompleteServices',

  data: function () {
    return {
      searchItems: [],
      search: "",

      isOpen: false,
      isLoadSearch: false
    }
  },

  props: {
    value: {},

    customOptions: {
      type: Array,
      default: () => {
        return []
      }
    },
    useCustomOptions: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    serviceIcon: function () {
      if (this.isOpen) {
        return null
      }
      return String(this.value?.icon_id)
    },
    serviceName: function () {
      if (this.isOpen) {
        return this.$t('languagesList.Выберите_услугу')
      }
      return this.value?.name || this.$t('languagesList.Выберите_услугу')
    },

    options: function () {
      if (String(this.search || '').length >= 2) {
        return this.searchItems || []
      }
      if (this.useCustomOptions) {
        return this.customOptions || []
      }
      return this.$store?.state?.["languages-list"]?.services || []
    },

    isLoadServicesAll: function () {
      if (this.useCustomOptions) {
        return false
      }
      return this.$store.state["languages-list"]?.isLoadServicesAll || false
    }
  },

  watch: {
    search: function () {
      this.isLoadSearch = Boolean(String(this.search).length >= 2)
      clearTimeout(timeOutSearch);
      timeOutSearch = setTimeout(() => {
        this.setSearchItems();
      }, 1000);
    },
    isOpen: async function () {
      if (this.isOpen && this.isLoadServicesAll && !this.useCustomOptions) {
        await this.$store.dispatch("languages-list/getAllServicesList")
      }
    }
  },

  methods: {
    changeOpen: function (value) {
      this.isOpen = value;

      if (!value) {
        this.search = "";
        this.searchItems = [];
      }
    },
    changeItem: function (item) {
      this.$emit("change", item);
      this.changeOpen(false);
    },

    setSearchItems: function () {
      const search = String(this.search || "").toLowerCase();
      if (search.length < 2) {
        this.searchItems = [];
        return
      }

      this.isLoadSearch = true;
      let items = this.$store?.state?.["languages-list"]?.services || [];
      if (this.useCustomOptions) {
        items = this.customOptions;
      }
      this.searchItems = items.filter((item) => {
        return Boolean(
          String(item?.nameLowerCase || '').includes(search) ||
          String(item?.nameTranscriptionEnRu || '').includes(search) ||
          String(item?.nameTranscriptionRuEn || '').includes(search)
        )
      })

      this.isLoadSearch = false;
    },
  }
}

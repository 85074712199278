//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PopupRepeatBuyNumber",
  emits: ['onClose'],
  props: {
    timerForRequest: {
      type: Number,
      default: 0,
    },
    currentCountry: {
      type: Object,
      default: () => ({}),
    },

    currentService: {
      type: Object,
      default: () => ({}),
    },
    requestCounter: {
      type: Number,
      default: 0
    }
  },

  mounted() {
    const bar = document.querySelector('.bar');

    bar.style.transitionDuration = `${this.timerForRequest}s`;

    setTimeout(() => {
      bar.style.width = '100%';
    }, 100);
  },

  methods: {
    close() {
      this.$emit('onClose');
    }
  }
}

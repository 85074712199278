//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import localization from "~/assets/language/localization";
import {UserMixin} from "~/node_modules/sitis-nuxt/lib/mixins";
import Loader from "~/components/loader/Loader.vue";
import ServiceListItem from "~/components/profile/ServiceListItem.vue";
import ServiceListLoader from "~/components/profile/ServicesListLoader.vue";
import RequestNewServiceForm from "~/components/profile/RequestNewServiceForm.vue";
import YandexMetricaMixin from "~/mixin/YandexMetricaMixin";


export default {
  name: "servicesList",
  mixins: [UserMixin, YandexMetricaMixin],
  components: {
    Loader,
    ServiceListItem,
    ServiceListLoader,
    RequestNewServiceForm,
  },
  emits: ["onUpdateNumbersList"],

  async created() {
    this.isLoading = true;
    if (!this.$store.getters['countries/isCountriesListLoaded']) {
      await this.$store.dispatch('countries/setCountriesList');
      this.countrysList = this.$store.getters['countries/countriesList'];

      this.isLoading = false;

    } else {
      this.countrysList = this.$store.getters['countries/countriesList'];

      this.isLoading = false;
    }

    let currentCountryDefoultId = this.$defaultCountrySelection();

    let currentCounrtyId = this.$cookies.get("selectedCountryId");

    currentCounrtyId = Boolean(typeof currentCounrtyId === "number")
      ? currentCounrtyId
      : currentCountryDefoultId;

    this.currentCountry = this.countrysList.find(
      (t) => t.external_id == currentCounrtyId
    );
  },

  watch: {
    getServicesList() {
      this.getServicesList.length
        ? (this.isShowNewServiceForm = false)
        : (this.isShowNewServiceForm = true);
    },
  },

  data() {
    return {
      isAddTowishList: false,
      isLoading: false,
      isShowNewServiceForm: false,
      isSearching: false,
      searchStr: "",
      searchOfResult: [],

      localization,
      currentCountry: {
        external_id: "",
        name: "",
        icon: "",
      },
      countrysList: [],

      timeOutSearch: null,

      isUpdateList: false
    };
  },
  computed: {
    isUserAuth() {
      return this.$store.getters['user/isLoggedIn'];
    },
    isShowUpdateBtn() {
      return Boolean(
        this.getServicesList &&
        !this.getServicesList.length &&
        !this.isLoading &&
        !this.isLoadingServicesList &&
        !this.isSearching &&
        this.searchStr === '',
      )
    },

    isWholesale() {
      return this.$store.getters["services/isWholesale"];
    },

    isShowMore() {
      return this.$store.getters["services/isShowMore"].isVisibleLoadMore;
    },
    getServicesList() {
      // Инициализация списка услуг
      let listServices = [...this.currentNumbers];

      // Получение списка избранных услуг
      let favoritesServices = this?.$cookies?.get("favorites") || "";
      favoritesServices = favoritesServices.split("&");
      // --------------------------------

      // Фильтрация списка по поиску
      const searchLowerCase = (this.searchStr || "").toLowerCase();
      listServices = listServices.filter((t) => {
        const nameLowerCase = (t?.name || "").toLowerCase();

        return Boolean(nameLowerCase.indexOf(searchLowerCase) > -1);
      });
      // ---------------------------

      // Сортировка по избранным
      const listServicesFavorites = [...listServices].filter((t) =>
        Boolean(favoritesServices.includes(t?.service_external_id))
      );
      const listServicesNotFavorites = [...listServices].filter(
        (t) => !Boolean(favoritesServices.includes(t?.service_external_id))
      );
      // -----------------------

      return [...listServicesFavorites, ...listServicesNotFavorites];
    },

    isLoadingServicesList: function () {
      return this.$store?.state?.services?.isLoading;
    },


    currentNumbers: function () {
      return this.$store?.state?.services?.services || [];
    },

    countrysListPopular: function () {
      return (this.countrysList || []).filter((t) => t.popular)
    },
  },
  methods: {
    onToggleWholesale(value) {
      if (this.isWholesale === value) {
        return;
      }

      this.$cookies.set('isWholesale', value);
      this.$store.dispatch("services/onToggleWholesale", {
        isWholesale: value,
      });
    },

    loadMore() {
      this.$store.dispatch('services/onLoadMore');
    },

    async backToList() {
      this.isShowNewServiceForm = false;
      this.searchStr = "";
      await this.$store.dispatch('services/onSearchAction', this.searchStr);
    },

    showNewServiceForm() {
      this.isShowNewServiceForm = true;

      let searchScrollContainerPositionTop =
        document.getElementById("tm-serviceList");

      searchScrollContainerPositionTop.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },

    async getPriceFromCurrentCounrty(event) {
      // Получение актиного кода страницы
      const currentCountry = event?.external_id || this.currentCountry.external_id;

      // Запись в куки активной страны
      await this.$cookies.set("selectedCountryId", String(currentCountry), {
        path: "/",
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 5))
      });

      // Получение услуг по новой стране
      await this.$store.dispatch("services/getListServices", {
        isResetPagination: true,
        isLoadMore: false,
      });
    },
    async setNewCurrentCounrty(country) {
      this.isUpdateList = true;
      await this.getPriceFromCurrentCounrty(country);
      this.currentCountry = country;
      this.isUpdateList = false;
    },

    async onBuyNumber(number) {
      if (!this.isLoggedIn) {
        this.$router.push(this.localePath("/registration?goback=1"));
        return null;
      }
      let balance = await this.getBalanc();
      if (balance < number.price) {
        this.$router.push(this.localePath("/profile/pay"));
      }
      this.isLoading = true;
      let countryId = number.country_external_id;
      let numberId = number.service_external_id;


      this.yandexGoalCheck('buy_left-sidebar_attempt');
      this.yandexSendUserInfo({
        userId: this?.user?.id,
        userEmail: this?.user?.email
      })

      const newNumber = await this.$api.agent.transport
        .get(`sms-users/get-number/${countryId}/${numberId}`)
        .catch((err) => {
          let error = JSON.parse(err?.request?.response);
          this.$toast.error(error.message || "Error");
          this.isLoading = false;
          return null;
        });
      if (newNumber) {
        this.$emit("onUpdateNumbersList");
        if (this.$route.path !== "/profile") {
          this.$router.push(this.localePath("/profile"));
        }

        this.yandexGoalCheck('buy_left-sidebar_success');

        this.yandexSendUserID({
          userId: this?.user?.id,
        })


        this.isLoading = false;
        this.$store.commit("active-numbers/updateIndex");
      }
    },
    async getBalanc() {
      const balance = await this.$api.agent.transport.get("sms-users/balance");
      return balance?.data;
    },

    async onSearch() {
      this.isSearching = true;
      let searchStr = this.searchStr;
      if (this.timeOutSearch) {
        clearTimeout(this.timeOutSearch)
      }
      this.timeOutSearch = setTimeout(async () => {
        await this.$store.dispatch('services/onSearchAction', searchStr);
        this.isSearching = false;
      }, 1000)
    },
  },
  beforeDestroy() {
    document.body.classList.remove('hasServicesList')
  },
  async mounted() {
    if (this.$route.params.pathMatch === 'opt' || this.$route.params.pathMatch === 'bulk') {
      this.onToggleWholesale(true);
    }

    document.body.classList.add('hasServicesList');
    this.searchStr = this.$store.getters['services/queryString'] || '';
  },
};

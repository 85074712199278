//
//
//
//
//
//
//
//
//

export default {
  name: 'IconSortTriangle',
  props: {
    active: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    triangleBorderColor() {
      return this.active ? '#FB7800' : '#A6AAAD';
    },
    triangleFillColor() {
      return this.active ? '#FB7800' : 'none';
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  UserMixin
} from "~/node_modules/sitis-nuxt/lib/mixins";
export default {
  props: [
    "error"
  ],

  mixins: [
    UserMixin
  ],

  mounted: async function () {
    await this.getUser();
    await this.$store.dispatch("services/getListServices", {
      isResetPagination: true,
      isLoadMore: false,
    });
  }
};

const setScript = async () => {
  const linkScript = "/js/sourcebuster.min.js";

  const elementScript = document.createElement("script");
  elementScript.type = 'text/javascript';
  elementScript.src = linkScript;
  document.head.appendChild(elementScript);
}

export default async (context) => {
  setTimeout(async () => {
    await setScript();
  }, 10000)
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _59d2a430 = () => interopDefault(import('../pages/authorization/index.vue' /* webpackChunkName: "pages/authorization/index" */))
const _2f593429 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _3701d20b = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))
const _4554db12 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _6e015a18 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _150f1080 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _a8515440 = () => interopDefault(import('../pages/request-password/index.vue' /* webpackChunkName: "pages/request-password/index" */))
const _637460a2 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _3fb97a2a = () => interopDefault(import('../pages/success-payment/index.vue' /* webpackChunkName: "pages/success-payment/index" */))
const _066b0e50 = () => interopDefault(import('../pages/marketplace/account/index.vue' /* webpackChunkName: "pages/marketplace/account/index" */))
const _0499c2c4 = () => interopDefault(import('../pages/marketplace/registration/index.vue' /* webpackChunkName: "pages/marketplace/registration/index" */))
const _121afe8c = () => interopDefault(import('../pages/partner/activations-history/index.vue' /* webpackChunkName: "pages/partner/activations-history/index" */))
const _26876f65 = () => interopDefault(import('../pages/partner/price-regulator/index.vue' /* webpackChunkName: "pages/partner/price-regulator/index" */))
const _66b6e474 = () => interopDefault(import('../pages/partner/sim-card/index.vue' /* webpackChunkName: "pages/partner/sim-card/index" */))
const _68098038 = () => interopDefault(import('../pages/partner/statistic/index.vue' /* webpackChunkName: "pages/partner/statistic/index" */))
const _a71adb38 = () => interopDefault(import('../pages/partner/withdrawal-funds/index.vue' /* webpackChunkName: "pages/partner/withdrawal-funds/index" */))
const _f4786746 = () => interopDefault(import('../pages/profile/history/index.vue' /* webpackChunkName: "pages/profile/history/index" */))
const _30f3eaf4 = () => interopDefault(import('../pages/profile/loyalty-program/index.vue' /* webpackChunkName: "pages/profile/loyalty-program/index" */))
const _7c4ed31e = () => interopDefault(import('../pages/profile/pay/index.vue' /* webpackChunkName: "pages/profile/pay/index" */))
const _07877881 = () => interopDefault(import('../pages/profile/referral-program/index.vue' /* webpackChunkName: "pages/profile/referral-program/index" */))
const _3332f0b1 = () => interopDefault(import('../pages/profile/sale-agreement/index.vue' /* webpackChunkName: "pages/profile/sale-agreement/index" */))
const _7f4cc0e0 = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _7a129810 = () => interopDefault(import('../pages/profile/statistics/index.vue' /* webpackChunkName: "pages/profile/statistics/index" */))
const _1305f450 = () => interopDefault(import('../pages/profile/tariffs/index.vue' /* webpackChunkName: "pages/profile/tariffs/index" */))
const _7f9e5dc8 = () => interopDefault(import('../pages/signup/confirm-email.vue' /* webpackChunkName: "pages/signup/confirm-email" */))
const _d65ec420 = () => interopDefault(import('../pages/marketplace/account/adding-product/index.vue' /* webpackChunkName: "pages/marketplace/account/adding-product/index" */))
const _47187b65 = () => interopDefault(import('../pages/marketplace/account/balance/index.vue' /* webpackChunkName: "pages/marketplace/account/balance/index" */))
const _cdf04c1a = () => interopDefault(import('../pages/marketplace/registration/originalIndex.vue' /* webpackChunkName: "pages/marketplace/registration/originalIndex" */))
const _2da292e1 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _07403dce = () => interopDefault(import('../pages/bot-link/_link.vue' /* webpackChunkName: "pages/bot-link/_link" */))
const _44ce41a6 = () => interopDefault(import('../pages/confirm/_token.vue' /* webpackChunkName: "pages/confirm/_token" */))
const _2d8b31f0 = () => interopDefault(import('../pages/countries/_.vue' /* webpackChunkName: "pages/countries/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/authorization",
    component: _59d2a430,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___en"
  }, {
    path: "/blog",
    component: _2f593429,
    pathToRegexpOptions: {"strict":true},
    name: "blog___en"
  }, {
    path: "/cn",
    components: {
      default: _3701d20b
    },
    name: "index___cn"
  }, {
    path: "/partner",
    component: _4554db12,
    pathToRegexpOptions: {"strict":true},
    name: "partner___en"
  }, {
    path: "/profile",
    component: _6e015a18,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/registration",
    component: _150f1080,
    pathToRegexpOptions: {"strict":true},
    name: "registration___en"
  }, {
    path: "/request-password",
    component: _a8515440,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___en"
  }, {
    path: "/ru",
    components: {
      default: _3701d20b
    },
    name: "index___ru"
  }, {
    path: "/search",
    component: _637460a2,
    pathToRegexpOptions: {"strict":true},
    name: "search___en"
  }, {
    path: "/success-payment",
    component: _3fb97a2a,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___en"
  }, {
    path: "/tr",
    components: {
      default: _3701d20b
    },
    name: "index___tr"
  }, {
    path: "/cn/authorization",
    component: _59d2a430,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___cn"
  }, {
    path: "/cn/blog",
    component: _2f593429,
    pathToRegexpOptions: {"strict":true},
    name: "blog___cn"
  }, {
    path: "/cn/partner",
    component: _4554db12,
    pathToRegexpOptions: {"strict":true},
    name: "partner___cn"
  }, {
    path: "/cn/profile",
    component: _6e015a18,
    pathToRegexpOptions: {"strict":true},
    name: "profile___cn"
  }, {
    path: "/cn/registration",
    component: _150f1080,
    pathToRegexpOptions: {"strict":true},
    name: "registration___cn"
  }, {
    path: "/cn/request-password",
    component: _a8515440,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___cn"
  }, {
    path: "/cn/search",
    component: _637460a2,
    pathToRegexpOptions: {"strict":true},
    name: "search___cn"
  }, {
    path: "/cn/success-payment",
    component: _3fb97a2a,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___cn"
  }, {
    path: "/marketplace/account",
    component: _066b0e50,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account___en"
  }, {
    path: "/marketplace/registration",
    component: _0499c2c4,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration___en"
  }, {
    path: "/partner/activations-history",
    component: _121afe8c,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___en"
  }, {
    path: "/partner/price-regulator",
    component: _26876f65,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___en"
  }, {
    path: "/partner/sim-card",
    component: _66b6e474,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___en"
  }, {
    path: "/partner/statistic",
    component: _68098038,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___en"
  }, {
    path: "/partner/withdrawal-funds",
    component: _a71adb38,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___en"
  }, {
    path: "/profile/history",
    component: _f4786746,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___en"
  }, {
    path: "/profile/loyalty-program",
    component: _30f3eaf4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___en"
  }, {
    path: "/profile/pay",
    component: _7c4ed31e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___en"
  }, {
    path: "/profile/referral-program",
    component: _07877881,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___en"
  }, {
    path: "/profile/sale-agreement",
    component: _3332f0b1,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___en"
  }, {
    path: "/profile/settings",
    component: _7f4cc0e0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___en"
  }, {
    path: "/profile/statistics",
    component: _7a129810,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___en"
  }, {
    path: "/profile/tariffs",
    component: _1305f450,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___en"
  }, {
    path: "/ru/authorization",
    component: _59d2a430,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___ru"
  }, {
    path: "/ru/blog",
    component: _2f593429,
    pathToRegexpOptions: {"strict":true},
    name: "blog___ru"
  }, {
    path: "/ru/partner",
    component: _4554db12,
    pathToRegexpOptions: {"strict":true},
    name: "partner___ru"
  }, {
    path: "/ru/profile",
    component: _6e015a18,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ru"
  }, {
    path: "/ru/registration",
    component: _150f1080,
    pathToRegexpOptions: {"strict":true},
    name: "registration___ru"
  }, {
    path: "/ru/request-password",
    component: _a8515440,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___ru"
  }, {
    path: "/ru/search",
    component: _637460a2,
    pathToRegexpOptions: {"strict":true},
    name: "search___ru"
  }, {
    path: "/ru/success-payment",
    component: _3fb97a2a,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___ru"
  }, {
    path: "/signup/confirm-email",
    component: _7f9e5dc8,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___en"
  }, {
    path: "/tr/authorization",
    component: _59d2a430,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___tr"
  }, {
    path: "/tr/blog",
    component: _2f593429,
    pathToRegexpOptions: {"strict":true},
    name: "blog___tr"
  }, {
    path: "/tr/partner",
    component: _4554db12,
    pathToRegexpOptions: {"strict":true},
    name: "partner___tr"
  }, {
    path: "/tr/profile",
    component: _6e015a18,
    pathToRegexpOptions: {"strict":true},
    name: "profile___tr"
  }, {
    path: "/tr/registration",
    component: _150f1080,
    pathToRegexpOptions: {"strict":true},
    name: "registration___tr"
  }, {
    path: "/tr/request-password",
    component: _a8515440,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___tr"
  }, {
    path: "/tr/search",
    component: _637460a2,
    pathToRegexpOptions: {"strict":true},
    name: "search___tr"
  }, {
    path: "/tr/success-payment",
    component: _3fb97a2a,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___tr"
  }, {
    path: "/cn/marketplace/account",
    component: _066b0e50,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account___cn"
  }, {
    path: "/cn/marketplace/registration",
    component: _0499c2c4,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration___cn"
  }, {
    path: "/cn/partner/activations-history",
    component: _121afe8c,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___cn"
  }, {
    path: "/cn/partner/price-regulator",
    component: _26876f65,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___cn"
  }, {
    path: "/cn/partner/sim-card",
    component: _66b6e474,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___cn"
  }, {
    path: "/cn/partner/statistic",
    component: _68098038,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___cn"
  }, {
    path: "/cn/partner/withdrawal-funds",
    component: _a71adb38,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___cn"
  }, {
    path: "/cn/profile/history",
    component: _f4786746,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___cn"
  }, {
    path: "/cn/profile/loyalty-program",
    component: _30f3eaf4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___cn"
  }, {
    path: "/cn/profile/pay",
    component: _7c4ed31e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___cn"
  }, {
    path: "/cn/profile/referral-program",
    component: _07877881,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___cn"
  }, {
    path: "/cn/profile/sale-agreement",
    component: _3332f0b1,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___cn"
  }, {
    path: "/cn/profile/settings",
    component: _7f4cc0e0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___cn"
  }, {
    path: "/cn/profile/statistics",
    component: _7a129810,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___cn"
  }, {
    path: "/cn/profile/tariffs",
    component: _1305f450,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___cn"
  }, {
    path: "/cn/signup/confirm-email",
    component: _7f9e5dc8,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___cn"
  }, {
    path: "/marketplace/account/adding-product",
    component: _d65ec420,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product___en"
  }, {
    path: "/marketplace/account/balance",
    component: _47187b65,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance___en"
  }, {
    path: "/marketplace/registration/originalIndex",
    component: _cdf04c1a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex___en"
  }, {
    path: "/ru/marketplace/account",
    component: _066b0e50,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account___ru"
  }, {
    path: "/ru/marketplace/registration",
    component: _0499c2c4,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration___ru"
  }, {
    path: "/ru/partner/activations-history",
    component: _121afe8c,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___ru"
  }, {
    path: "/ru/partner/price-regulator",
    component: _26876f65,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___ru"
  }, {
    path: "/ru/partner/sim-card",
    component: _66b6e474,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___ru"
  }, {
    path: "/ru/partner/statistic",
    component: _68098038,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___ru"
  }, {
    path: "/ru/partner/withdrawal-funds",
    component: _a71adb38,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___ru"
  }, {
    path: "/ru/profile/history",
    component: _f4786746,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___ru"
  }, {
    path: "/ru/profile/loyalty-program",
    component: _30f3eaf4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___ru"
  }, {
    path: "/ru/profile/pay",
    component: _7c4ed31e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___ru"
  }, {
    path: "/ru/profile/referral-program",
    component: _07877881,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___ru"
  }, {
    path: "/ru/profile/sale-agreement",
    component: _3332f0b1,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___ru"
  }, {
    path: "/ru/profile/settings",
    component: _7f4cc0e0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___ru"
  }, {
    path: "/ru/profile/statistics",
    component: _7a129810,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___ru"
  }, {
    path: "/ru/profile/tariffs",
    component: _1305f450,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___ru"
  }, {
    path: "/ru/signup/confirm-email",
    component: _7f9e5dc8,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___ru"
  }, {
    path: "/tr/marketplace/account",
    component: _066b0e50,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account___tr"
  }, {
    path: "/tr/marketplace/registration",
    component: _0499c2c4,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration___tr"
  }, {
    path: "/tr/partner/activations-history",
    component: _121afe8c,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___tr"
  }, {
    path: "/tr/partner/price-regulator",
    component: _26876f65,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___tr"
  }, {
    path: "/tr/partner/sim-card",
    component: _66b6e474,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___tr"
  }, {
    path: "/tr/partner/statistic",
    component: _68098038,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___tr"
  }, {
    path: "/tr/partner/withdrawal-funds",
    component: _a71adb38,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___tr"
  }, {
    path: "/tr/profile/history",
    component: _f4786746,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___tr"
  }, {
    path: "/tr/profile/loyalty-program",
    component: _30f3eaf4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___tr"
  }, {
    path: "/tr/profile/pay",
    component: _7c4ed31e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___tr"
  }, {
    path: "/tr/profile/referral-program",
    component: _07877881,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___tr"
  }, {
    path: "/tr/profile/sale-agreement",
    component: _3332f0b1,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___tr"
  }, {
    path: "/tr/profile/settings",
    component: _7f4cc0e0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___tr"
  }, {
    path: "/tr/profile/statistics",
    component: _7a129810,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___tr"
  }, {
    path: "/tr/profile/tariffs",
    component: _1305f450,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___tr"
  }, {
    path: "/tr/signup/confirm-email",
    component: _7f9e5dc8,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___tr"
  }, {
    path: "/cn/marketplace/account/adding-product",
    component: _d65ec420,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product___cn"
  }, {
    path: "/cn/marketplace/account/balance",
    component: _47187b65,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance___cn"
  }, {
    path: "/cn/marketplace/registration/originalIndex",
    component: _cdf04c1a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex___cn"
  }, {
    path: "/ru/marketplace/account/adding-product",
    component: _d65ec420,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product___ru"
  }, {
    path: "/ru/marketplace/account/balance",
    component: _47187b65,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance___ru"
  }, {
    path: "/ru/marketplace/registration/originalIndex",
    component: _cdf04c1a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex___ru"
  }, {
    path: "/tr/marketplace/account/adding-product",
    component: _d65ec420,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product___tr"
  }, {
    path: "/tr/marketplace/account/balance",
    component: _47187b65,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance___tr"
  }, {
    path: "/tr/marketplace/registration/originalIndex",
    component: _cdf04c1a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex___tr"
  }, {
    path: "/cn/blog/:slug",
    component: _2da292e1,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___cn"
  }, {
    path: "/cn/bot-link/:link?",
    component: _07403dce,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___cn"
  }, {
    path: "/cn/confirm/:token?",
    component: _44ce41a6,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___cn"
  }, {
    path: "/ru/blog/:slug",
    component: _2da292e1,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___ru"
  }, {
    path: "/ru/bot-link/:link?",
    component: _07403dce,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___ru"
  }, {
    path: "/ru/confirm/:token?",
    component: _44ce41a6,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___ru"
  }, {
    path: "/tr/blog/:slug",
    component: _2da292e1,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___tr"
  }, {
    path: "/tr/bot-link/:link?",
    component: _07403dce,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___tr"
  }, {
    path: "/tr/confirm/:token?",
    component: _44ce41a6,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___tr"
  }, {
    path: "/tr/countries/*",
    component: _2d8b31f0,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___tr"
  }, {
    path: "/cn/countries/*",
    component: _2d8b31f0,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___cn"
  }, {
    path: "/ru/countries/*",
    component: _2d8b31f0,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___ru"
  }, {
    path: "/blog/:slug",
    component: _2da292e1,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___en"
  }, {
    path: "/bot-link/:link?",
    component: _07403dce,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___en"
  }, {
    path: "/confirm/:token?",
    component: _44ce41a6,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___en"
  }, {
    path: "/tr/*",
    component: _3701d20b,
    pathToRegexpOptions: {"strict":true},
    name: "all___tr"
  }, {
    path: "/cn/*",
    component: _3701d20b,
    pathToRegexpOptions: {"strict":true},
    name: "all___cn"
  }, {
    path: "/ru/*",
    component: _3701d20b,
    pathToRegexpOptions: {"strict":true},
    name: "all___ru"
  }, {
    path: "/countries/*",
    component: _2d8b31f0,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___en"
  }, {
    path: "/*",
    component: _3701d20b,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralSearchCountryTableRow from "~/cms/blocks/general-search/GeneralSearchCountryTableRow.vue";
// Иконки старого дизайна (если вдруг лучше)
// import IconNumbersASK from "~/cms/blocks/general-search/sort/IconNumbersASK.vue";
// import IconNumbersDESK from "~/cms/blocks/general-search/sort/IconNumbersDESK.vue";

import IconSortTriangle from "~/cms/blocks/general-search/sort/IconSortTriangle.vue";
import IconSortTriangleReverse from "~/cms/blocks/general-search/sort/IconSortTriangleReverse.vue";

export default {
  name: "SortComponent",
  components: {
    GeneralSearchCountryTableRow,
    // IconNumbersASK,
    // IconNumbersDESK,
    IconSortTriangle,
    IconSortTriangleReverse
  },
  emits: ['changeCurrentSort'],
  props: {
    servicesList: {
      type: Array,
      default: () => []
    },
    currentSort: {
      type: String,
      default: '',
    },
  },

  computed: {
    getHeaderItems() {
      return [
        {
          label: this.$t('sortComponent.country'),
          // IconASK: IconNumbersASK,
          // IconDESK: IconNumbersDESK,
          IconASK: IconSortTriangle,
          IconDESK: IconSortTriangleReverse,
          sortName: "name",
        },
        {
          label: this.$t('generalSearch.tableHeaderAmount'),
          // IconASK: IconNumbersASK,
          // IconDESK: IconNumbersDESK,
          IconASK: IconSortTriangle,
          IconDESK: IconSortTriangleReverse,
          sortName: "count",
        },
        {
          label: this.$t('serviceCard.price'),
          // IconASK: IconNumbersDESK,
          // IconDESK: IconNumbersASK,
          IconASK: IconSortTriangle,
          IconDESK: IconSortTriangleReverse,
          sortName: "price",
        },
      ];
    },
  },
  methods: {
    componentIconSort: function (item) {
      const currentSortName = item?.sortName;
      const isActiveSortName = Boolean(this.currentSort.indexOf(currentSortName) > -1);

      // Если не активная или активная но сортировка в них
      if (!!isActiveSortName && this.currentSort[0] === "-") {
        return item.IconDESK
      }

      return item.IconASK
    },

    changeSort: function (sortItem) {
      if (sortItem.sortName === 'name') {

        if (sortItem.sortName === this.currentSort) {
          this.$emit('changeCurrentSort', `-${sortItem.sortName}`);
          return
        }
        this.$emit('changeCurrentSort', sortItem.sortName);
        return;
      }

      if (sortItem.sortName === this.currentSort) {
        this.$emit('changeCurrentSort', `-${sortItem.sortName}`);
        return
      }
      if (this.currentSort[0] === "-" && sortItem.sortName === this.currentSort.substring(1)) {
        this.$emit('changeCurrentSort', 'name');
        return;
      }

      this.$emit('changeCurrentSort', sortItem.sortName);
    },

    isActiveColumnSort(item) {
      const currentSortName = item?.sortName;
      const isActiveSortName = Boolean(this.currentSort.indexOf(currentSortName) > -1);

      return Boolean(isActiveSortName)
    },
  },
}

export const state = () => ({});

export const actions = {
  /**
   * Получение конфигурации сайта
   */
  async nuxtServerInit(store: any, app: any): Promise<void> {
    try {
      const currentLocale = app.$api.configProps.headers?.['x-user-locale'] || '';

      if (!currentLocale) {
        const locale = app.i18n.locale;
        app.$api.setLocale(locale);
      }

      const cookieSessionToken = app.$cookies.get('sessionToken');
      const isWholesale = app.$cookies.get('isWholesale');

      // const currency = app.$cookies.get('currency');
      // console.log(currency, "currencyServerInit")
      // if (currency) {
      //   store.commit("currency/setCurrency", currency);
      // }

      await store.dispatch('currency/setCurrencyConvertValue');
      await store.dispatch('countries/setCountriesList');
      store.commit("services/isWholesaleToggle", isWholesale);


      const res = await store.dispatch('appState/GET_REQUEST', '/configs', {root: true});

      if (!cookieSessionToken) {
        app.$cookies.set('sessionToken', res.headers['x-session-token'], {
          path: '/',
          maxAge: 60 * 60 * 24 * 60,
        });
      }

      store.commit('local-settings/setHeaders', app?.req?.headers);

      store.commit('appState/setConfig', res.data, {root: true});
      await store.commit('languages-list/setActiveServiceId', app.$cookies.get('selectedServiceId') || res.data?.var?.['Сервис выбора по умолчанию']);
      // await store.commit('languages-list/setActiveServiceIdExternal', app.$cookies.get('selectedServiceIdExternal') || res.data?.var?.['Сервис выбора по умолчанию (аренда)']);
      await store.commit('languages-list/setActiveServiceIdExternal', res.data?.var?.['Сервис выбора по умолчанию (аренда)']);
      await store.dispatch('languages-list/initTypeNumberReceiving');
      await store.dispatch('languages-list/initIsWholesale');
      await store.dispatch('languages-list/initActiveServiceId');
      await store.dispatch('languages-list/initActiveLanguageId');
    } catch (e) {
      console.error('Невозможно получить конфигурацию ', e);
    }
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "currencyToggle",
  emits: ['onClick'],
  data() {
    return {
      isOpenList: false
    }
  },

  computed: {
    getCurrency() {
      return this.$store.getters["currency/currentCurrency"];
    },
  },
  methods: {
    onClose() {
      this.isOpenList = false;
    },

    onToggle() {
      this.onClose();
      this.$cookies.set('currency', this.getCurrency === 'rub' ? 'usd' : 'rub');
      const currency = this.getCurrency === 'rub' ? 'usd' : 'rub'
      this.$store.commit('currency/setCurrency', currency);
      this.$emit('onClick')
    }
  },
}

export default function ({req, res, redirect}) {
  if (process.server) {
    const lowerCaseUrl = req.url.toLowerCase().replace(/\/*\//g, "/");

    if (lowerCaseUrl === req.url) {
      return req.url;
    }
    
    return redirect(301, lowerCaseUrl);
  }
}

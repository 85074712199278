export const state = () => ({
    countriesList: [],
});

export const mutations = {
    setCountriesList(state, list) {
        state.countriesList = list;
    }
}

export const actions = {
    async setCountriesList({commit}) {
        try {
            const list = await this.$api.agent.transport.get("country?fields=name,slug,icon,external_id,popular");
            commit('setCountriesList', (list?.data || []));

            return list.data || [];
        } catch (e) {
            return [];
        }
    }
}

export const getters = {
    countriesList: ({countriesList}) => countriesList,
    isCountriesListLoaded: ({countriesList}) => Boolean(countriesList.length),
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "GeneralSearchCountryTableRow",
  props: {
    service: {
      type: Object,
      default: () => ({})
    },
    dataType: {
      type: String,
      default: '',
    },
  },
  computed: {
    getLink() {
      return this.dataType === 'country' ? `/${this.service.service_slug}` : `/countries/${this.service.country_slug}`;
    },
    getCurrency() {
      return this.$store.getters["currency/currentCurrency"];
    },
  },
  methods: {
    countLanguages(count = 0) {

      if (count <= 0) {
        return this.$t('servicesList.quantityFew')
      }

      return [
        count,
        this.$t('servicesList.quantity')
      ].join(" ")
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "ServiceListItem",
    emits: ["updateList", "onBuyNumber"],
    data() {
        return {
            isAdded: false,
        };
    },

    mounted: function () {

        let favoritesItems = this.$cookies.get("favorites") || "";

        if (favoritesItems.length) {
            favoritesItems = favoritesItems.split("&");
            this.isAdded = Boolean(
              favoritesItems.find((t) => t === this.number.service_external_id)
            );
        }
    },

    methods: {
        onAddToWishList() {
            if (this.isAdded) {
                this.isAdded = false;
                let currentCookies = this.$cookies.get("favorites") || "";

                if (currentCookies == "") {
                    return this.$cookies.remove("favorites");
                }

                currentCookies = currentCookies.split("&");
                currentCookies = currentCookies.filter(
                  (t) => t != this.number.service_external_id
                );

                currentCookies = currentCookies.join("&");
                this.$cookies.set("favorites", currentCookies);
            } else {
                this.isAdded = true;
                let currentCookies = this?.$cookies?.get("favorites");

                let newCookies = !currentCookies
                  ? this.number.service_external_id
                  : `${currentCookies}&${this.number.service_external_id}`;
                this.$cookies.set("favorites", newCookies);
            }
            this.$emit("updateList");
        },
    },
    props: {
        number: {
            type: Object,
        },
        isLoading: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    computed: {
        getCurrency() {
            return this.$store.getters["currency/currentCurrency"];
        },
        isWholesale() {
            return this.$store.getters["services/isWholesale"];
        },

        hreflang: function () {
            return this?.$i18n?.locale || this?.$i18n?.defaultLocale || "en";
        },

        getCardMessage() {
            const number = this.number;

            if (number.service_external_id === "wb") {
                return this.$t("servicesList.orderFrom100numbers");
            }

            if (
              number?.country_external_id === 0 &&
              number.service_external_id === "wa"
            ) {
                return this.$t("servicesList.numberTemporarilyUnavailable");
            }
        },

        getNumberCount() {
            return this?.number?.count || 0;

            // || createRandomNumber(100, 1000, 10)
        },

        isDisabled: function () {
            // return false;

            const number = this.number;
            const yemeksepeti = Boolean(
              +number?.country_external_id === 62 &&
              number.service_external_id === "yi"
            );

            return yemeksepeti;

        },
    },
};





























































import Header from "~/components/common/Header.vue";
import Footer from "~/components/common/Footer.vue";
import {Component, mixins} from "nuxt-property-decorator";
import ServicesList from '~/components/profile/ServicesList.vue';
import LanguagesList from '~/components/profile/LanguagesList.vue';
import {
  AppMixin,
  CmsMixin,
  UserMixin,
  UserAuthMixin
} from "~/node_modules/sitis-nuxt/lib/mixins";
import getCanonical from "~/helpers/canonical";
import languagesInfo from "~/constant/languiges-info.json";

const PASS_REQUEST_TIMESTAMP = "PASS_REQUEST_TIMESTAMP";

@Component({
  head: function (this: LayoutDefault) {
    const meta: any = this.headerMetaAsync;
    const alternate = this.getAlternateLink;
    const lang = this?.$i18n?.locale || this?.$i18n?.defaultLocale || "en";

    return {
      __dangerouslyDisableSanitizers: ['script', 'innerHTML'],
      script: [
        {
          hid: 'test-script',
          innerHTML: `window.ChatraSetup = {language: '${lang}'};`,
          type: 'text/javascript',
          charset: 'utf-8'
        }
      ],

      htmlAttrs: {
        lang: this.$hreflangInitial(),
      },
      titleTemplate: ['%s', (this.appCfg?.suffixDivider || ""), (this.appCfg?.suffixTitle || "")].filter((t) => !!t).join(' '),
      meta: [...meta],
      link: [
        ...alternate,
        // { rel: 'canonical', href: this.getLinkCanonical },
      ],
    };
  },

  components: {
    Header,
    Footer,
    ServicesList,
    LanguagesList,
    // AdditionalCashbackAccrual,

    MobileMenu: () => import( "~/components/common/MobileMenu.vue"),
    LoadingModal: () => import("~/components/common/LoadingModal.vue"),
    WidgetAdmin: () => import( "~/node_modules/sitis-nuxt/components/widget-admin/WidgetAdmin.vue")
  },

  created(this: LayoutDefault) {
    if (process.client) {
      const isWholesale = this.$cookies.get('isWholesale') || false;
      const currency = this?.$cookies?.get('currency');
      this.$store.commit("services/isWholesaleToggle", isWholesale);
      if (currency) {
        this.$store.commit("currency/setCurrency", currency);
      }
    }
  },

  async fetch(this: LayoutDefault) {
    await this.getCustomMenu();

    if ((this.$store.state["languages-list"]?.servicesPopular || []).length <= 0) {
      await this.$store.dispatch("languages-list/getPopularServicesList");
    }
    if ((this.$store.state["languages-list"]?.services || []).length <= 0) {
      await this.$store.dispatch("languages-list/getServicesList");
    }

    if ((this.$store.state["services"]?.services || []).length <= 0) {
      await this.$store.dispatch("services/getListServices", {
        isResetPagination: true,
        isLoadMore: false,
      });
    }
  },

  computed: {
    linkCanonical: function () {
      const host = this.$store.state["local-settings"]?.headers?.host;
      const fullPath = (this.$route?.fullPath || '').substring(1);
      return getCanonical(host, fullPath);
    },
    headerRef: function () {
      return this.$refs.refHeader
    },

    isWidgetAdmin: function (this: LayoutDefault) {
      return Boolean(false)
    },
    isPopUpLoading: function (this: LayoutDefault) {
      return this.$store.state?.loading?.popUpLoading || false
    },
  }
})
export default class LayoutDefault extends mixins(
  AppMixin,
  UserMixin,
  UserAuthMixin,
  CmsMixin
) {
  $refs: any;
  $modal: any;
  $cookies: any;
  isShowMenu: boolean = false;
  $i18n: any;
  ChatraSetup: any;
  $api: any;
  $hreflangInitial: any;
  $recaptcha: any;
  langInfo: { [key: string]: any } = languagesInfo;
  $nuxt: any;
  _adminIdentity: any = null;

  async mounted(): Promise<void> {
    const currentLocale = this.$api.configProps.headers?.['x-user-locale'] || '';

    if (!currentLocale) {
      const locale = this.$i18n.locale;
      this.$api.setLocale(locale);
    }

    const host = '_' + window.location.host;
    if (host) {
      window.document.body.classList.add(host.replace('.', ''));
    }

    if (this.$i18n.locale === 'en') {
      this.$recaptcha.language = 'en';
    }
    if (this.$i18n.locale === 'cn') {
      this.$recaptcha.language = 'zh-TW';
    }
    if (this.$i18n.locale === 'tr') {
      this.$recaptcha.language = 'tr'
    }
    if (this.$i18n.locale === 'ru') {
      this.$recaptcha.language = 'ru'
    }

    const fullPath = this.$route.fullPath || "";
    const show_rent = String((fullPath.split("&")?.[0] || "").split("show_rent=")?.[1] || "");
    if (typeof show_rent !== "undefined") {
      this.$cookies.set('show-rent', show_rent, {
        path: '/',
        maxAge: 60 * 60 * 24 * 60,
      })
    }

    if (this.$i18n.locale === 'en' || this.$i18n.locale === 'cn') {
      this.$cookies.set('currency', 'usd');
      this.$store.commit('currency/setCurrency', 'usd');
    }

    // const lastUrlForPay = this.$cookies.get("lastUrlForPay");
    // if (!!lastUrlForPay) {
    //   this.$cookies.remove("lastUrlForPay");
    //   window.location.href = lastUrlForPay;
    // }

    const authToken = this.$cookies.get('authToken');
    if (!!authToken) {
      await this.getUser();
    } else {
      this.$store.commit('user/setLoadingUser', false)
    }

    // Получение списка сервисов
    if ((this.$store.state.services?.services || []).length <= 0) {
      await this.$store.dispatch("services/getListServices", {
        isResetPagination: true,
        isLoadMore: false,
      });
    }
    const isHasTelegramCookies = this.$cookies.get('telegram_or_phone');

    await this.$store.dispatch("commerceOrders/checkLastOrders");

    this._adminIdentity = this.$cookies.get('_adminIdentity');

    if (this.isLoggedIn && isHasTelegramCookies) {
      const settings = {
        telegram: isHasTelegramCookies
      }

      const res = await this.$api.agent.transport
        .put("users/settings", {settings})
        .catch((err: any) => {
        });

      if (res.data) {
        this.$cookies.remove('telegram_or_phone');
      }
    }
  }

  async getCustomMenu(this: LayoutDefault) {
    if (this.menu.length > 0) {
      return null;
    }
    await this.getMenu();
  }

  get baseApiUrl() {
    return process?.env?.baseApiUrl;
  }

  get footerMenu(): any[] {
    const footerMenu = this.getCmsMenu("docs");
    return footerMenu.items as any[];
  }

  get countersFooter() {
    return this.appCfg?.counters?.footer || "";
  }

  get headerMetaAsync() {
    const config = this.$store.state?.appState?.config;
    const headMeta = config?.head?.meta || [];

    let meta: any = [];

    headMeta.map((item: any) => {
      meta.push({name: item.name, content: item.content});
    });

    meta = [
      ...meta,
      {
        hid: "og:title",
        name: "og:title",
        property: "og:title",
        content: this?.page?.page?.title || "",
      },
      {
        hid: "og:site_name",
        name: "og:site_name",
        property: "og:site_name",
        content: "GrizzlySMS",
      },
      {
        hid: "og:description",
        name: "og:description",
        property: "og:description",
        content: this?.page?.page?.description || "",
      },
      {
        hid: "apple-mobile-web-app-title",
        name: "apple-mobile-web-app-title",
        content: `apple-${this?.page?.page?.title || ""} `,
      },
      {
        hid: "og:image",
        name: "og:image",
        property: "og:image",
        content: `${this?.appCfg?.logoFront || 'logo'}`,
      },
      {
        hid: "og:url",
        name: "og:url",
        property: "og:url",
        content: `https://${process.server ? this.$nuxt.context.req.headers.host : window.location.host}${this.$route.fullPath}`,
      },
    ];

    return meta;
  }

  get versionLanguageService() {
    return this.$nuxt?.context?.env?.versionLanguageService || '0'
  }

  get getAlternateLink() {
    const isProd = !process.env.isDev;
    const fullLink = this.$route.fullPath === '/' ? '' : this.$route.fullPath;
    return Object.keys(this.langInfo).map((t) => {
      const domain = isProd ? this.langInfo?.[t].domain : this.langInfo?.[t].devDomain;
      return {
        rel: "alternate",
        href: `${domain}${fullLink}`,
        hreflang: this.langInfo?.[t]?.isDefault ? "x-default" : this.langInfo?.[t]?.locale === 'cn' ? 'zh' : this.langInfo?.[t]?.locale,
        hid: `alternate-default-${this.langInfo?.[t]?.locale}`
      }
    });
  }

  get getLinkCanonical() {
    const host = [this.$store.state["local-settings"]?.headers?.host, '/'].join('');
    const fullPath = (this.$route?.fullPath || '').substring(1);
    return getCanonical(host, fullPath);
  }
}

export const state = () => ({
  headers: {},
  host: '',
});

export const mutations = {
  setCurrentHost: function (state, host) {
    state.host = host;
  },
  setHeaders: function (state, headers) {
    state.headers = headers;
  },
};
